import React from "react";
import AppSection from "./landing/AppSection";
import Features from "./landing/Features";
import Summary from "./landing/Summary";
import Footer from "./landing/Footer";

const Landing = () => {
  return (
    <>
      <AppSection />
      <Features />
      <Summary />
      <Footer />
    </>
  );
};

export default Landing;
