import React from "react";
import Landing from "./screens/Landing";
import Navbar from "./components/Navbar";

const App = () => {
  return (
    <>
      <Navbar />
      <Landing />
    </>
  );
};

export default App;
