import React from "react";
import "./features.css";
import Line from "../../components/Line";
import book from "../../assets/images/book.svg";
import booki from "../../assets/images/booki.svg";
import analyze from "../../assets/images/analyze.svg";
import analyzei from "../../assets/images/analyzei.svg";
import visual from "../../assets/images/visual.svg";
import visuali from "../../assets/images/visuali.svg";
import create from "../../assets/images/create.svg";
import createi from "../../assets/images/createi.svg";
import solve from "../../assets/images/solve.svg";
import solvei from "../../assets/images/solvei.svg";
import FadeInSection from "../../components/Fade";

const features = [
  {
    title: "Turn your dreams into reality",
    description: book,
    image: booki,
  },
  {
    title: "Enhance your creativity",
    description: create,
    image: createi,
  },
  {
    title: "visualize your Subconscious",
    description: visual,
    image: visuali,
  },
  {
    title: "Solve real-world problems",
    description: solve,
    image: solvei,
  },
  {
    title: "analyze your dreamscapes",
    description: analyze,
    image: analyzei,
  },
];

const getLine = () => {
  const Layout = ({ children }) => <div className="line-lay">{children}</div>;

  return (
    <Layout>
      <Line />
    </Layout>
  );
};

const Feature = ({ title, description, image, mobile }) => {
  if (mobile) {
    return (
      <>
        <FadeInSection class="feature">
          <img src={image} alt={title} className="fi" />
          <h2>{title}</h2>
          <img src={description} alt={title} className="fd" />
          {getLine()}
        </FadeInSection>
      </>
    );
  }

  return (
    <>
      <FadeInSection class="feature">
        {getLine()}

        <h2>{title}</h2>
        <div className="feature-desc">
          <img src={image} alt={title} className="fi" />
          <img src={description} alt={title} className="fd" />
        </div>
        {getLine()}
      </FadeInSection>
    </>
  );
};

const Features = () => {
  return (
    <>
      <div className="features">
        {features.map((feature, index) => (
          <Feature {...feature} index={index} key={index} />
        ))}
      </div>
      <div className="features-mobile">
        {getLine()}

        {features.map((feature, index) => (
          <Feature {...feature} index={index} key={index} mobile />
        ))}
      </div>
    </>
  );
};

export default Features;
