import React from "react";
import "./summary.css";
import dtext from "../../assets/images/dreamy-txt.svg";
import logo from "../../assets/images/logo.svg";
import FadeInSection from "../../components/Fade";

const Summary = () => {
  return (
    <>
      <h2 className="smr-h2">Dream, Create, Analyze. Unleash Your Genius.</h2>
      <FadeInSection class="sm-l">
        <img
          alt="logo"
          src={logo}
          className="logo"
          style={{ width: 64, height: 64 }}
        />
        <img
          alt="Dreamy"
          src={dtext}
          className="logo-text"
          style={{ width: 265, height: 64, marginLeft: 24 }}
        />
      </FadeInSection>
      <h2 className="meet-team">
        Want to learn more about us?{" "}
        <a
          href="https://www.linkedin.com/company/dreamyapp/"
          target="_blank"
          rel="noreferrer"
        >
          <span>Meet the team.</span>
        </a>
      </h2>
    </>
  );
};

export default Summary;
