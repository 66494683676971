import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDIno4q8x-DIWAmIstsN_Liai2gqzy-SSI",
  authDomain: "dreamy-web.firebaseapp.com",
  projectId: "dreamy-web",
  storageBucket: "dreamy-web.appspot.com",
  messagingSenderId: "327910795896",
  appId: "1:327910795896:web:aaf0fa053e1ed178249b8d",
  measurementId: "G-Y5Y3QE29HY",
};

// Initialize Firebase
initializeApp(firebaseConfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
