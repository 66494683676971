import React from "react";
import dtext from "../assets/images/dreamy-txt.svg";
import logo from "../assets/images/logo.svg";
import "./navbar.css";

const Navbar = () => {
  return (
    <div className="nb">
      <img alt="logo" src={logo} className="logo" />
      <img alt="Dreamy" src={dtext} className="logo-text" />
    </div>
  );
};

export default Navbar;
