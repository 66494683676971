import React from "react";
import mbApp from "../../assets/images/dreamy-app.png";
import "./appSection.css";
import FadeInSection from "../../components/Fade";
import { TypeAnimation } from "react-type-animation";

const AppSection = () => {
  return (
    <div>
      <div className="nb nb2">
        <p className="share-dream">
          <span>NEW!</span> -{" "}
          <span style={{ textDecoration: "underline" }}>SHARE YOUR DREAM</span>
        </p>
      </div>
      <div className="app">
        <div className="app-slogan">
          <h1>
            <TypeAnimation
              sequence={[
                // Same substring at the start will only be typed out once, initially
                "Realize your dreams.",
                1500, // wait 1s before replacing "Mice" with "Hamsters"
              ]}
              wrapper="span"
              speed={30}
              // repeat={Infinity}
            />
          </h1>
          <h1>
            <TypeAnimation
              sequence={[
                500,
                // Same substring at the start will only be typed out once, initially
                "Uncover your subconscious.",
                1500, // wait 1s before replacing "Mice" with "Hamsters"
              ]}
              wrapper="span"
              speed={30}
              // repeat={Infinity}
            />
          </h1>
          <p>
            Welcome to{" "}
            <span>
              <TypeAnimation
                sequence={[
                  2500,
                  // Same substring at the start will only be typed out once, initially
                  "DREAMY",
                  500, // wait 1s before replacing "Mice" with "Hamsters"
                ]}
                wrapper="span"
                speed={30}
              />
            </span>
            - your{" "}
            <span>
              <TypeAnimation
                sequence={[
                  3750,
                  // Same substring at the start will only be typed out once, initially
                  "AI-powered",
                  500, // wait 1s before replacing "Mice" with "Hamsters"
                ]}
                wrapper="span"
                speed={30}
                style={{ color: "#fb4934" }}
              />
            </span>{" "}
            dream journal. Record, visualize, and analyze your dreams to create
            incredible{" "}
            <span className="vst">
              <TypeAnimation
                sequence={[
                  5000,
                  // Same substring at the start will only be typed out once, initially
                  "visual stories",
                  500, // wait 1s before replacing "Mice" with "Hamsters"
                ]}
                wrapper="span"
                speed={30}
                style={{ color: "#fb4934" }}
              />
            </span>
            that you can share.
          </p>
        </div>
        <FadeInSection>
          <img alt="Dreamy Mobile App" src={mbApp} />
        </FadeInSection>
      </div>
    </div>
  );
};

export default AppSection;
