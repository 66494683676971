import React from "react";
import "./footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <h5>Connect with us:</h5>
      <p>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/dreamy.app/"
        >
          instagram
        </a>{" "}
        <span>|</span>{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/company/dreamyapp/"
        >
          linkedin
        </a>{" "}
        <span>|</span>{" "}
        <a href="mailto:hello@dreamyapp.com?subject=Mail from our Website">
          email
        </a>
      </p>
    </div>
  );
};

export default Footer;
